import React from "react"
import { Link } from "gatsby"
import MarianePicture from "../components/marianepicture"
import Header from "../components/header"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import FontAwesome from "react-fontawesome"

const IndexPage = () => (
<Layout>
	<Container>
		<Row className="header mb-5">
			<Col>
				<Header />
			</Col>
		</Row>
		<Row className="justify-content-center">
			<Col className="col-10 col-md-10 col-lg-4 col-xl-5">
				<MarianePicture />
			</Col>
			<Col className="col-10 col-md-10 col-lg-7 col-xl-6 text-justify align-self-center">
				<p>
					<strong>Hello. Bonjour. Guten Tag. Grüäzi. こんにちは.</strong> I am a <a href="https://www.jsps.go.jp/english/e-fellow/">JSPS</a> postdoc fellow
					in the <a href="http://www.isi.imi.i.u-tokyo.ac.jp">Intelligent Systems and Informatics Laboratory</a> at the <a href="https://www.u-tokyo.ac.jp">University of Tokyo</a>.
					Additionally, I am a guest at <a href="https://www.asafas.kyoto-u.ac.jp/en/">Graduate School of Asian and African Area Studies</a> at <a href="https://www.kyoto-u.ac.jp">Kyoto University</a>. Previously, I got my PhD from a Urban Water Management group at <a href="https://www.eawag.ch">Eawag</a> under the supervision 
					of Max Maurer and Kris Villez. My special interest lies in monitoring and control of partly
					observable processes such as wastewater treatment processes.
				</p>
				<p>
					In my free time, I love photography, cooking, hiking, languages, writing, and travelling the world by train and with the bicycle.
				</p>
			</Col>
		</Row>
		<Row className="justify-content-center">
			<div className="social">
				<a href="https://www.researchgate.net/profile/Mariane_Schneider" className="fab fa-researchgate fa-3x fa-fw"></a>
				<a href="https://gitlab.com/Viraja" className="fab fa-gitlab fa-3x fa-fw"></a>
			</div>
		</Row>
	</Container>
</Layout>
)

export default IndexPage
