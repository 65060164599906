import React from "react"
import MarianePicture from "../components/marianepicture"
import FontAwesome from "react-fontawesome"
const Header = ({ name }) => (
<div></div>
)



export default Header
